

















































































































































































































import Vue from "vue";
import { settingsServices } from "@/services/settings.service";
import {
  ResponseListOfCoa,
  ResponseListOfTax,
  ResponseListOfTaxType,
} from "@/models/interface/settings.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { ResponsePagination } from "@/models/interface/common.interface";
import { debounceProcess } from "@/helpers/debounce";
import {
  currencyFormat,
  changeCurrencytoNumeric,
  numericdotOnly,
  currencyFormatDot,
} from "@/validator/globalvalidator";
import { masterServices } from "@/services/master.service";
import localStorageService from "@/services/localStorage.service";
import { IAuthorities } from "@/models/interface-v2/auth.interface";
import { accountingAccountService } from "@/services-v2/accounting-account.service";
import MNotificationVue from "@/mixins/MNotification.vue";
import { ResponseListAccountingAccount } from "@/models/interface-v2/accounting-account.interface";
import { getauthorities } from "@/helpers/cookie";
export default Vue.extend({
  name: "Tax",
  mixins: [
    MNotificationVue,
  ],
  data() {
    this.handleSearchCoa = debounceProcess(this.handleSearchCoa, 200);
    return {
      formatCurrency: currencyFormat,
      formatCurrencytoNumber: changeCurrencytoNumeric,
      formatCurrencyDot: currencyFormatDot,
      direction: "" as string,
      search: "" as string,
      isFormSubmitted: false as boolean,
      dataListTax: {} as ResponseListOfTax,
      dataListCoa: {} as ResponseListAccountingAccount,
      form: this.$form.createForm(this, { name: "tax" }),
      page: this.page || (1 as number),
      limit: this.limit || (20 as number),
      trigerdisable: false as boolean,
      titlemodalOpen: "TAX" as string,
      dataListTaxType: [] as ResponseListOfTaxType[],
      modalOpen: false as boolean,
      sort: "" as string,
      typemodal: "" as string,
      loadingTable: false as boolean,
      columnsTable: [
        {
          title: this.$t("lbl_tax_code"),
          dataIndex: "code",
          key: "taxcode",
          sorter: true,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_description"),
          dataIndex: "description",
          key: "description",
          sorter: true,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_tax_rate"),
          dataIndex: "rate",
          key: "taxrate",
          sorter: true,
          scopedSlots: { customRender: "isCurrency" },
        },
        {
          title: this.$t("lbl_tax_account"),
          dataIndex: "taxAccountDescription",
          key: "accountpayables",
          sorter: true,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_tax_type"),
          dataIndex: "taxType",
          key: "taxType",
          sorter: true,
          scopedSlots: { customRender: "isNull" },
        },
      ] as any,
      labelCol: { span: 6 },
      wrapperCol: { span: 16 },
      formRules: {
        rate: {
          label: this.$t("lbl_tax_rate"),
          name: "rate",
          placeholder: this.$t("lbl_type_here"),
          decorator: [
            "rate",
            {
              rules: [
                {
                  required: true,
                  message: () => this.$t("lbl_validation_required_error"),
                },
                {
                  validator: numericdotOnly,
                },
              ],
            },
          ],
        },
        code: {
          label: this.$t("lbl_tax_code"),
          name: "code",
          placeholder: this.$t("lbl_type_here"),
          decorator: [
            "code",
            {
              rules: [
                {
                  required: true,
                  message: () => this.$t("lbl_validation_required_error"),
                },
              ],
            },
          ],
        },
        tax: {
          label: this.$t("lbl_tax_account"),
          name: "type",
          placeholder: this.$t("lbl_type_here"),
          decorator: [
            "tax",
            {
              rules: [
                {
                  required: true,
                  message: () => this.$t("lbl_validation_required_error"),
                },
              ],
            },
          ],
        },
        taxType: {
          label: this.$t("lbl_tax_type"),
          name: "taxType",
          placeholder: this.$t("lbl_type_here"),
          decorator: [
            "taxType",
            {
              rules: [
                {
                  required: true,
                  message: () => this.$t("lbl_validation_required_error"),
                },
              ],
            },
          ],
        },
        description: {
          label: this.$t("lbl_description"),
          name: "description",
          placeholder: this.$t("lbl_type_here"),
          decorator: [
            "description",
            {
              rules: [
                {
                  required: true,
                  message: () => this.$t("lbl_validation_required_error"),
                },
              ],
            },
          ],
        },
      },
      userPrivileges: [] as IAuthorities[],
      authPrivilege: [] as string[],
    };
  },
  computed: {
    isAbleToCreate(): boolean {
      return !!this.userPrivileges.find(x => x.key === "accounting-tax" && (x.privilege.create));
    },
  },
  created() {
    this.setAuthorities();
    this.loadUserPrivileges();
    this.getListOfTax("");
    this.getListOfCoa("");
    this.getListOfMasterType();
  },
  methods: {
    setAuthorities(): void {
      const auth = getauthorities();
      auth.forEach(dataAuth => {
        if(dataAuth.actions?.length && dataAuth?.name === "accounting-tax") {
          this.authPrivilege = dataAuth.value;
          this.columnsTable.push(
            {
              title: this.$t("lbl_action"),
              dataIndex: "operation",
              key: "operation",
              scopedSlots: { customRender: "operation" },
              button: dataAuth.actions,
              width: "120px",
              align: "center",
              fixed: "right",
            },
          );
        }
      });
    },
    loadUserPrivileges(): void {
      this.userPrivileges = localStorageService.loadUserPrivilege();
    },
    getListOfMasterType(): void {
      let params = {
        name: "TAX_TYPE",
      } as RequestQueryParamsModel;
      masterServices.listMaster(params).then((res: any) => {
        res.map((dataObject, index) => (dataObject.key = index));
        this.dataListTaxType = res;
      });
    },
    onBlur(value, form) {
      if (value.target.value && !this.form.getFieldError(`${form}`)) {
        let dataobj = {} as any;
        dataobj[form] = this.formatCurrencyDot(value.target.value);
        this.form.setFieldsValue(dataobj);
      }
    },
    onFocus(value, form) {
      if (value.target.value && !this.form.getFieldError(`${form}`)) {
        let dataobj = {} as any;
        dataobj[form] = this.formatCurrencytoNumber(value.target.value);
        this.form.setFieldsValue(dataobj);
      }
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].componentOptions.children[1].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    onSorterChange(response): void {
      if (response.sort.order) {
        this.direction = response.sort.order === "ascend" ? "asc" : "desc";
        this.sort = `${response.sort.field}:${this.direction}`;
      } else {
        this.resetFilter(false);
      }
      this.getListOfTax("");
    },
    reponseEditTable(response): void {
      this.trigerdisable = false;
      this.typemodal = "edit";
      this.titlemodalOpen = this.$t("lbl_edit_tax").toString();
      this.dataDetail = "/" + response.data.id;
      this.resetFilter(false);
      this.getListOfTax("/" + response.data.id);
    },
    reponseDeleteTable(response): void {
      const path = "/" + response.data.id;
      settingsServices
        .deleteTax(path)
        .then(() => {
          this.resetFilter(false);
          this.getListOfTax("");
        })
        .catch(() => {
          this.showNotifError("notif_process_fail");
        });
    },
    resetFilter(getlist): void {
      this.direction = "";
      this.search = "";
      if (getlist) this.getListOfTax("");
    },
    reponseSearchInput(response): void {
      if (response) this.search = `description~*${response}*_OR_code~*${response}*`;
      else this.search = "";
      this.getListOfTax("");
    },
    responsePageSizeChange(response: ResponsePagination): void {
      this.limit = response.size;
      this.page = 1;
      this.getListOfTax("");
    },
    responseCurrentPageChange(response: ResponsePagination): void {
      this.page = response.page;
      this.getListOfTax("");
    },
    createNew(): void {
      this.modalOpen = true;
      this.typemodal = "create";
      this.form.resetFields();
      this.titlemodalOpen = this.$t("lbl_create_new_tax").toString();
      this.resetFilter(false);
    },
    handleSearchCoa(value) {
      let paramsListOfCoa = {
        page: 0,
        limit: 10,
        search: "isParent~false",
      } as RequestQueryParamsModel;
      if (value)
        paramsListOfCoa.search =
          `code~*${value}*_OR_description~*${value}*_AND_` +
          paramsListOfCoa.search;
      accountingAccountService.getListCOA(paramsListOfCoa).then((data) => {
        this.dataListCoa = data;
      });
    },
    getListOfCoa(path: string): void {
      let params: any;
      if (!path) {
        params = {
          limit: this.limit,
          page: this.page - 1,
        } as RequestQueryParamsModel;
        if (this.search) params.search = this.search;
        if (this.direction) params.sorts = this.sort;
      }
      this.loadingTable = true;
      accountingAccountService.getListCOA(params)
        .then((res) => {
          res.data.forEach((dataObject, index) => (dataObject["key"] = index));
          this.dataListCoa = res;
          if (!this.dataListCoa.data.find(x => x.id === path)) {
            this.handleOptionCoa(path);
          }
        })
        .catch(() => this.showNotifError("notif_process_fail"))
        .finally(() => (this.loadingTable = false));
    },
    async handleOptionCoa(path: string): Promise<void> {
      try {
        const res = await accountingAccountService.getListCOA({search: `secureId~${path}`});
        this.dataListCoa.data.push(res.data[0]);
      } catch (error) {
        this.showNotifError("notif_process_fail");
      }
    },
    getListOfTax(path: string): void {
      let params: any;
      if (!path) {
        params = {
          limit: this.limit,
          page: this.page - 1,
          sorts: "createdDate:desc",
        } as RequestQueryParamsModel;
        if (this.search) params.search = this.search;
        if (this.direction) params.sorts = this.sort;
      }
      this.loadingTable = true;
      settingsServices.listOfTax(params, path)
        .then((res: any) => {
          if (!path) {
            res.data.forEach((dataObject, index) => (dataObject.key = index));
            this.dataListTax = res;
          } else {
            this.getListOfCoa(res.taxAccountId);
            const idtimeout = setTimeout(() => {
              this.form.setFieldsValue({
                rate: res.rate ? this.formatCurrency(res.rate) : "0.00",
                description: res.description ? res.description : "-",
                tax: res.taxAccountId ? res.taxAccountId : "-",
                code: res.code,
                taxType: res.taxType,
              });
              clearTimeout(idtimeout);
            }, 200);
            this.modalOpen = true;
          }
        })
        .catch((err) => this.showNotifError("notif_process_fail"))
        .finally(() => (this.loadingTable = false));
    },
    createNewTax(datapost): void {
      settingsServices
        .createTax(datapost)
        .then((res) => {
          if (res) {
            this.modalOpen = false;
            this.getListOfTax("");
            this.form.resetFields();
            this.$message.success(this.$t("notif_create_success").toString());
          }
        })
        .finally(() => (this.isFormSubmitted = false));
    },
    updateTax(datapost): void {
      settingsServices
        .updateTax(datapost, this.dataDetail)
        .then((res) => {
          if (res) {
            this.modalOpen = false;
            this.getListOfTax("");
            this.form.resetFields();
            this.$message.success(this.$t("notif_update_success").toString());
          }
        })
        .finally(() => (this.isFormSubmitted = false));
    },
    submitForm(e: Event, type: "create" | "edit"): void {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          const datapost = {
            rate: this.formatCurrencytoNumber(values.rate),
            description: values.description,
            taxAccountId: values.tax ? values.tax : null,
            code: values.code,
            taxType: values.taxType,
          };
          this.isFormSubmitted = true;
          if (type === "create") {
            this.createNewTax(datapost);
          } else {
            this.updateTax(datapost);
          }
        } else {
          this.$notification.error({
            message: this.$t("lbl_error_title").toString(),
            description: this.$t("lbl_form_is_mandatory").toString(),
          });
        }
      });
    },
    handleCancel(): void {
      this.modalOpen = false;
    },
  },
});
